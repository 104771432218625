
import Vue from 'vue';
import NimsModifyStep02Template from '@/templates/nims/NimsModifyStep02Template.vue';
import { getModifyGoodsInfo } from '@/api/infra-renew';
import { ChageOrderCompleteResponse } from '@/api/infra-renew/types';

export default Vue.extend({
  name: 'NimsModifyStep02Brain',
  components: { NimsModifyStep02Template },
  props: {},
  data() {
    return {
      isRender: false,
      goodsSeqNo: '',
      paymentSeqNo: '',
      changeOrderInfo: {} as ChageOrderCompleteResponse,
    };
  },
  watch: {},
  async mounted() {
    this.$GlobalLoading.show();
    this.goodsSeqNo = this.$route.query?.seq_no ? String(this.$route.query?.seq_no) : '';
    this.paymentSeqNo = this.$route.query?.payment_seqno ? String(this.$route.query?.payment_seqno) : '';

    if (this.goodsSeqNo && this.paymentSeqNo) {
      await this.getModifyReqestCompleteFnc();
    }

    this.$GlobalLoading.hide();
  },
  methods: {
    async getModifyReqestCompleteFnc() {
      try {
        const { data } = await getModifyGoodsInfo(this.goodsSeqNo, this.paymentSeqNo);
        console.log(data.data.after);
        this.changeOrderInfo = data.data;
        this.isRender = true;
      } catch (error) {
        console.log(error);
        alert('일시적 오류가 발생했습니다.');
      }
    },
  },
});
