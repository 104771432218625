
import Vue, { PropType } from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import { ChageOrderCompleteResponse } from '@/api/infra-renew/types';

export default Vue.extend({
  name: 'NimsModifyStep02Template',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout },
  props: {
    changeOrderInfo: {
      type: Object as PropType<ChageOrderCompleteResponse>,
      default: () => {
        return {
          after: { goods_id: '', goods_name: '', expose_name: '' },
          before: { goods_id: '', goods_name: '', expose_name: '' },
          contract_group_seqno: '',
          group_name: '',
          ordernum: '',
          request_date: '',
        };
      },
    },
  },
  data() {
    return {
      formTitle,
      myGabiaUrl: `${process.env.VUE_APP_MY_GABIA_URL}/dashboard#/`,
      gicUrl: `${process.env.VUE_APP_GIC_URL}/${this.changeOrderInfo.contract_group_seqno}/dashboard#/`,
      mainProductSeqNo: '',
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.mainProductSeqNo = this.$route.query?.seq_no ? String(this.$route.query?.seq_no) : '';
  },
  methods: {},
});
